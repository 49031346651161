import Vue from 'vue'
import VueRouter from 'vue-router'
// 解决相同链接点击报错问题start
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }
    // end
Vue.use(VueRouter)
const routes = [

    // 登录
    {
        path: '/login',
        component: () =>
            import ('@v/login.vue'),
    },
    {
        path: '/register',
        component: () =>
            import ('@v/register.vue')
    },
    // 政策资讯

    // 首页
    {
        path: '/',
        component: () =>
            import ('@v/home.vue'),
        children: [{
                path: '',
                component: () =>
                    import ('@v/index.vue')
            },
            // 政策资讯
            {
                path: 'PolicyInformation',
                component: () =>
                    import ('@v/PolicyInformation.vue')
            },
            // 自贸专栏
            {
                path: 'freeTrade',
                component: () =>
                    import ('@v/freeTrade.vue')
            },
            // 企业互动
            {
                path: 'interaction',
                component: () =>
                    import ('@v/interaction.vue')
            },
            // 企业服务分类
            {
                path: 'serverType',
                component: () =>
                    import ('@v/serverType.vue')
            },
            // 企业服务
            {
                path: 'enterpriseServices',
                component: () =>
                    import ('@v/enterpriseServices.vue')
            },
            // 招聘
            {
                path: 'recruit',
                component: () =>
                    import ('@v/recruit.vue')
            },
            // 金融服务
            {
                path: 'service',
                component: () =>
                    import ('@v/service.vue')
            },
            // 企业详情

            {
                path: 'enterpriseDetails',
                component: () =>
                    import ('@v/enterpriseDetails.vue')
            },

            // 招聘详情
            {
                path: 'RecruitmentDetails',
                component: () =>
                    import ('@v/RecruitmentDetails.vue')
            },
            // 资讯详情
            {
                path: 'InformationDetails',
                component: () =>
                    import ('@v/InformationDetails.vue')
            },
            // 所有载体
            {
                path: 'AllCarriers',
                component: () =>
                    import ('@v/AllCarriers.vue')
            },
            // 载体详情
            {
                path: 'carrierDetails',
                component: () =>
                    import ('@v/carrierDetails.vue')
            },
            // 服务详情
            {
                path: 'serviceDetails',
                component: () =>
                    import ('@v/serviceDetails.vue')
            },
            // 金融服务详情
            {
                path: 'JrDetil',
                component: () =>
                    import ('@v/JrDetil.vue')
            },
            // 供应详情
            {
                path: 'Gdetile',
                component: () =>
                    import ('@v/Gdetile.vue')
            },


            // 自贸办相关
            // 自贸办资讯
            {
                path: 'ZMBPolicyInformation',
                component: () =>
                    import ('@v/ZMb/ZMBPolicyInformation.vue')
            },
            // 自贸办资讯详情
            {
                path: 'ZMBpolicyDetail',
                component: () =>
                    import ('@v/ZMb/ZMBpolicyDetail.vue')
            },
            // 自贸办服务
            {
                path: 'ZMBservice',
                component: () =>
                    import ('@v/ZMb/ZMBservice.vue')
            },
            // 自贸办服务详情
            {
                path: 'ZMBserviceDetails',
                component: () =>
                    import ('@v/ZMb/ZMBserviceDetails.vue')
            },
            // 自贸办相关政策
            {
                path: 'ZMBpolicy',
                component: () =>
                    import ('@v/ZMb/ZMBpolicy.vue')
            },

            // 自贸办创新案例
            {
                path: 'ZMBcase',
                component: () =>
                    import ('@v/ZMb/ZMBcase.vue')
            },
            // 自贸办创新案例详情
            {
                path: 'ZMBcaseDetail',
                component: () =>
                    import ('@v/ZMb/ZMBcaseDetail.vue')
            },
            // 自贸办功能定位
            {
                path: 'ZMBorientation',
                component: () =>
                    import ('@v/ZMb/ZMBorientation.vue')
            },
            // ZMBoriDetail
            {
                path: 'ZMBoriDetail',
                component: () =>
                    import ('@v/ZMb/ZMBoriDetail.vue')
            },
            // 自贸办相关政策详情
            {
                path: '/ZMBiframe',
                component: () =>
                    import ('@v/ZMb/ZMBiframe.vue')
            },

        ]
    },

    // 需求详情
    {
        path: '/Xdetile',
        component: () =>
            import ('@v/home2.vue'),
        children: [ // 需求详情
            {
                path: '',
                component: () =>
                    import ('@v/Xdetile.vue')
            },

            // 载体
            {
                path: '/carrier',
                component: () =>
                    import ('@v/carrier.vue')
            },
            {
                path: '/map',
                component: () =>
                    import ('@v/map.vue')
            },
            {
                path: '/chat',
                component: () =>
                    import ('@v/chat.vue')
            },
        ]
    },


    {
        path: '/companyCenter',
        component: () =>
            import ('@v/companyCenter.vue'),
        children: [


            // 个人中心
            {
                path: '/companyCenter/personalCenter',
                component: () =>
                    import ('@v/personalCenter.vue')
            },
            // 添加企业
            {
                path: '/companyCenter/addCompant',
                component: () =>
                    import ('@v/addCompant.vue')
            },
            // 企业中心

            //载体中心
            {
                path: '/companyCenter/carrierCenter',
                component: () =>
                    import ('@v/carrierCenter.vue')
            },
            // 消息中心
            {
                path: '/companyCenter/newsCenter',
                component: () =>
                    import ('@v/newsCenter.vue')
            },

            // 企业中心拆分
            {
                path: '/companyCenter/centerHome',
                component: () =>
                    import ('@v/centerHome.vue'),
                children: [{
                        path: '',
                        component: () =>
                            import ('@v/serveCenter.vue')
                    },
                    // 自贸专栏服务中心
                    {
                        path: '/ZMserverCenter',
                        component: () =>
                            import ('@v/ZMb/ZMserverCenter.vue')
                    },
                    {
                        path: '/financeCenter',
                        component: () =>
                            import ('@v/financeCenter.vue')
                    },
                    {
                        path: '/recruitCenter',
                        component: () =>
                            import ('@v/recruitCenter.vue')
                    },
                    {
                        path: '/supplyCenter',
                        component: () =>
                            import ('@v/supplyCenter.vue')
                    },
                    {
                        path: '/basicCenter',
                        component: () =>
                            import ('@v/basicCenter.vue')
                    },
                    {
                        path: '/detailedCenter',
                        component: () =>
                            import ('@v/detailedCenter.vue')
                    },
                ]

            },

        ]
    },





]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
<template>
  <div >
    <router-view> </router-view>
    <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport" />
  </div>
</template>


<script>

window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    });
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  };

</script>

<style lang="scss">
.viewer-footer{
  // display: none;
  .viewer-title{
    display: none;
  }
 .viewer-prev, .viewer-next{
    display: none;
  }
}
.van-image-preview{
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items:center;
                justify-content:center;
  background-color: rgba(0, 0, 0, .9);
  top: 0;
  z-index: 9999999999999999999;
}
.van-image__img{
  max-width: 100vw;
}
*{
  margin: 0 ;
  padding: 0;
  word-break: break-all;
}
a{
  text-decoration: none;
}
ul{
  list-style: none;
}
.el-table .cell{
   text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bottom_right .imputImg{
  float: left;
  margin-bottom: 20px;
  max-width: 80%!important;
}
.bodybox{
  min-height: 300px;

};
.htmlbox{
  word-break: break-all;
}
@media screen and (max-width: 500px) {
  .el-message-box {
    width: 70% !important;
  }
}
</style>

import NProgress from 'nprogress'
// import Vue from 'vue'
import router from '../router'
import 'nprogress/nprogress.css'
import axios from 'axios'

//服务地址
let url = window.location.href
let SerHostUrl = 'http://47.94.161.230:8001'

if (url.indexOf('117.35.158.142') > -1) SerHostUrl = 'http://117.35.158.142:8084' //测试
else if (url.indexOf('cblm.dingbang.co') > -1) SerHostUrl = 'http://47.108.92.109:8043' //预发布
else if (url.indexOf('cbechanye.com') > -1) SerHostUrl = 'http://47.94.161.230:8001' //正式
else SerHostUrl = 'http://47.94.161.230:8001/'

// 请求封装
let OAurl = SerHostUrl

// 请求封装
const instance = axios.create({
    // 跟路径
    baseURL: OAurl,
    // 请求超时时间
    timeout: 30000,

});

// 添加请求拦截器
instance.interceptors.request.use(function(config) {
    // 在发送请求之前做些什么
    let userId = window.localStorage.getItem('userId')
    config.headers["X-Token"] = userId ? userId : '';
    NProgress.start()
    return config;
}, function(error) {
    // 对请求错误做些什么
    console.log(error);
    NProgress.done()
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function(response) {

    let nowUrl = window.location.href
    if (response.status == 401 && nowUrl.indexOf('login') != -1) {
        router.push('/login')
        return
    }
    // 对响应数据做点什么
    NProgress.done()
    return response;
}, function(error) {
    let nowUrl = window.location.href
    if (error.status == 401 && nowUrl.indexOf('login') != -1) {
        router.push('/login')
        return
    }
    // 对响应错误做点什么
    NProgress.done()
    return Promise.reject(error);
});
let get = async function(url, params) {
    let { data } = await instance.get(url, { params })
    return data
}
let post = async function(url, params) {
    let { data } = await instance.post(url, params)
    return data
}
export {
    get,
    post

}
function timeFormat(time, type) {

    // var u = navigator.userAgent;
    // if (u.indexOf('AppleWebKit') > -1 || !!u.match(/\(i[^;]+;( U;)? CPU.Mac OS X/)) {

    //     console.log("非苹果系统");
    // } else {

    //     time = time.replace(/\-/g, "/");
    // }
    // console.log(time);
    if (!time || time == 'undefined') return
    let a = time.indexOf("T")
    if (a <
        0) {
        time = time.replace(/-/g, "/");
    }
    var d = new Date(time);
    // var d = new Date(time.replace(/-/g, '/'))
    // var d = new Date(time.replace(/-/g, '/'))

    var year = d.getFullYear(); //年  
    var month = d.getMonth() + 1; //月  
    var day = d.getDate(); //日  

    var hh = d.getHours(); //时  
    var mm = d.getMinutes(); //分  
    var ss = d.getSeconds(); //秒  

    var clock = year + "-";
    var low = clock = year + "-";
    var three = ''
    if (month < 10) {
        clock += "0";
        low += "0";
        three += "0";
    }

    clock += month + "-";
    low += month + "-";
    three += month + "-";
    if (day < 10) {
        clock += "0";
        low += "0";
        three += '0'
    }

    clock += day + " ";
    low += day;
    three += day
    if (hh < 10)
        clock += "0";

    clock += hh + ":";
    if (mm < 10) clock += '0';
    clock += mm
    if (type != 2) {
        clock += ":";
        if (ss < 10) clock += '0';
        clock += ss;
    }
    if (type == 1) {
        return (low);
    } else if (type == 2) {
        return (clock);
    } else if (type == 3) {
        return three
    }
}
export default {
    timeFormat
}
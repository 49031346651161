import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import timeCC from './util/time'
Vue.prototype.timeformart = timeCC
import './plugins'
Vue.config.productionTip = false

import SlideVerify from 'vue-monoplasty-slide-verify';

//服务地址
let url = window.location.href
let SerHostUrl = ''

if (url.indexOf('117.35.158.142') > -1) SerHostUrl = 'http://117.35.158.142:8084' //测试
else if (url.indexOf('cblm.dingbang.co') > -1) SerHostUrl = 'http://47.108.92.109:8043' //预发布
else if (url.indexOf('cbechanye.com') > -1) SerHostUrl = 'http://47.94.161.230:8001' //正式
else SerHostUrl = 'http://47.94.161.230:8001'

Vue.prototype.urlOA = SerHostUrl



import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer'; //图片浏览组件
Vue.component('Viewer', Viewer).default;
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
});
Viewer.setDefaults({
    Options: {
        'inline': true,
        'button': true,
        'navbar': false,
        'title': false,
        'toolbar': true,
        'tooltip': true,
        'movable': true,
        'zoomable': true,
        'rotatable': true,
        'scalable': true,
        'transition': true,
        'fullscreen': true,
        'keyboard': true,
        'url': 'data-source'
    }
});

// document.title = '西安浐灞产业联盟交流平台'
Vue.use(SlideVerify);

new Vue({
    router,
    store,

    render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.path) {
        _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }

    next();
})


// 百度统计

var _hmt = _hmt || [];
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?853f99f1c8443aa8b8b3e2e29b5ac48d";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();


// 拖拽全局指令
Vue.directive('drag', {
    //1.指令绑定到元素上回立刻执行bind函数，只执行一次
    //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
    //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
    bind: function() {},
    //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
    inserted: function(el) {
        console.log(el);
        let odiv = el; //获取当前元素
        let firstTime = '',
            lastTime = '';
        el.onmousedown = function(e) {
            var disx = e.pageX - el.offsetLeft;
            var disy = e.pageY - el.offsetTop;
            // 给当前元素添加属性，用于元素状态的判断
            odiv.setAttribute('ele-flag', false)
            odiv.setAttribute('draging-flag', true)
            firstTime = new Date().getTime();
            document.onmousemove = function(e) {
                el.style.left = e.pageX - disx + 'px';
                el.style.top = e.pageY - disy + 'px';
            }
            document.onmouseup = function(event) {
                document.onmousemove = document.onmouseup = null;
                lastTime = new Date().getTime();
                if ((lastTime - firstTime) > 200) {
                    odiv.setAttribute('ele-flag', true)
                    event.stopPropagation()
                }
                setTimeout(function() {
                    odiv.setAttribute('draging-flag', false)
                }, 100)
            }
        }
    }
})